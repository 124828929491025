<template>
  <div class="contactUs">
    <div class="content_area">
      <div class="title"><span>//</span>联系我们</div>
      <div class="main">
        <div>
          上海市报关协会是由本市在海关注册的从事报关行业同业企业以及其他相关经济组织自愿组成、实行行业服务和自律管理的非营利性的社会团体法人。协会成立于1998年12月，是全国第一家地方性报关协会，承办至今历经四届。
          2017年10月，按照中共中央办公厅、国务院办公厅关于印发〈行业协会商会与行政机关脱钩总体方案〉要求，协会正式实施了脱钩改革，真正实行行业协会的“社会化、市场化"。
        </div>
        <div>
          协会始终坚持“六项服务”，竭诚为会员单位提供“通关服务、咨询服务、培训服务、信息服务、帮困服务、维权服务”，把服务作为协会工作的出发点和落脚点。
        </div>
        <div>
          上海市报关协会自成立以来，坚持民主办会、服务会员、构建企业与海关交流平台，引导企业处理好企业经营与行业利益、企业诚信与行业自律、行业精神文明与社会和谐之间的关系。面对政府加快推进职能转变、简政放权的新形势和协会的宗旨，协会适时提出“紧贴海关的业务改革与建设，紧贴企业的需求与发展”的工作指导思想，当好会员的“娘家人”和政府与企业之间的“中间人”，积极倡导“诚信、自律、合作、奋进”的行业精神，推动行业和谐共嬴和科学发展。
        </div>
      </div>
      <div class="add_form_box">
        <div class="adress">
          <mapPage :longitude="longitude" :dimension="dimension"></mapPage>
        </div>
        <div class="formbox">
          <div class="ticps">
            如果您在使用本网站时有任何意见或建议，请写下您的意见或者建议。我们会尽快答复您，感谢您的关注~
          </div>
          <div class="name_tel">
            <el-input
              size="small"
              class="input"
              v-model="evaluateVal.name"
              placeholder="名字"
              style="width: 180px"
            />
            <el-input
              size="small"
              class="input1"
              type="number"
              v-model="evaluateVal.phone"
              placeholder="电话"
              style="width: 242px"
            />
          </div>
          <div class="email">
            <el-input
              size="small"
              class="input1"
              v-model="evaluateVal.email"
              placeholder="邮箱"
            />
          </div>
          <div>
            <el-input
              type="textarea"
              class="textarea"
              placeholder="请写下您想说的话"
              v-model="evaluateVal.content"
            />
          </div>
          <div class="btn">
            <el-button
              size="mini"
              type="primary"
              class="submit"
              @click="submit"
              :loading="btnLoading"
              >提交</el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validateTelephone, validateEmail } from "~/baseUtils/validate";
import mapPage from "~/baseComponents/map";
export default {
  components: { mapPage },
  metaInfo: {
    title: "联系我们",
  },
  data() {
    return {
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      longitude: 121.517503,
      dimension: 31.25576,
      btnLoading: false,
      evaluateRules: {
        phone: {
          validator: async (rule, value, callback) => {
            if (!validateTelephone(value)) {
              this.codeBtnDisabled = true;
              callback("手机号格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
        email: {
          validator: async (rule, value, callback) => {
            if (!validateEmail(value)) {
              this.codeBtnDisabled = true;
              callback("邮箱格式错误");
            } else {
            }
          },
          trigger: "blur",
        },
      },
    };
  },
  methods: {
    async submit() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (!this.evaluateVal.content) {
        this.$message.warning("输入建议不能为空");
        return;
      }
      this.btnLoading = true;
      this.evaluateVal.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_index/suggestionSave",
        this.evaluateVal
      );
      this.btnLoading = false;
      if (res.success) {
        this.$message.success("提交成功");
        this.evaluateVal = {};
      } else {
      }
    },
  },
  mounted() {
    console.log("this.PJSource", this.PJSource);
  },
};
</script>
<style lang="less" scoped>
.contactUs {
  background: #ffffff;
  .content_area {
    padding: 30px 0 50px;
    .title {
      font-size: 24px;
      span {
        color: #e3954f;
        margin-right: 10px;
      }
    }
    .main {
      margin: 24px 0;
      div {
        text-indent: 2em;
        line-height: 25px;
      }
    }
    .add_form_box {
      display: flex;
    }
    .adress {
      height: 440px;
      width: 699px;
    }
    .formbox {
      background: #4a5a81;
      width: 502px;
      padding: 36px 32px 28px 32px;
      .ticps {
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
        line-height: 20px;
      }
      .name_tel {
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
      }
      .email {
        margin: 16px 0;
      }
      .btn {
        margin-top: 16px;
        text-align: center;
        button {
          width: 115px;
          height: 30px;
        }
      }
    }
  }

  /deep/.el-textarea__inner {
    min-height: 154px !important;
  }
  /deep/.el-form-item__error {
    color: #d8563a;
    margin-left: 16px;
  }
}
</style>